<template>
  <div>
    <v-card class="pa-5">
      <div class="d-flex align-center mb-6">
        <h2 class="text-subtitle-1 font-weight-bold mr-4">
          {{ $t("helpdesk.title") }}
        </h2>
        <v-btn
          color="primary"
          dark
          class="ml-auto"
          @click="$router.push({ name: 'AddIssueCustomerService' })"
        >
          {{ $t('issues.createIssue') }}
        </v-btn>
      </div>
      <v-card-text>
        <v-simple-table v-if="conversations.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("helpdesk.users") }}</th>
                <th class="text-left">{{ $t("helpdesk.topic") }}</th>
                <th class=""></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in conversations" :key="item.threadId">
                <td class="text-left">{{ item.user.fullName }}</td>
                <td class="text-left">{{ item.topic }}</td>
                <td class="text-right">
                  <v-btn
                    depressed
                    color="primary"
                    @click="takeConversation(item.threadId)"
                    >{{ $t("helpdesk.take") }}</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'HelpDesk',
  components: {},
  data () {
    return {
      // conversations: []
    }
  },
  async created () {
    await this.$store.dispatch('helpdesk')
  },
  computed: {
    conversations () {
      return this.$store.getters.helpConversations
    }
  },
  methods: {
    // async getConversations () {
    //   this.loading = true
    //   try {
    //     const { data } = await Service.get('/Helpdesk/GetConversations')
    //     this.conversations = data
    //   } catch (e) {}

    //   this.loading = false
    // },
    async takeConversation (id) {
      this.loading = true
      try {
        await Service.post('/Helpdesk/TakeConversation', { threadId: id })
        // await this.getConversations()
        await this.$store.dispatch('helpdesk')
      } catch (e) {}

      this.loading = false
    }
  }
}
</script>
